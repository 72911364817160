.ctm_acc_setting .MuiList-root form input {
    color: transparent;
}
.ctm_acc_setting .MuiList-root form input:focus {
    color: #000;
}

.ctm_acc_setting div:hover .MuiOutlinedInput-notchedOutline {
    border-color: #c4c4c4;
}
.ctm_acc_setting .MuiGrid-root.MuiGrid-item button {
    padding: 13px 24px;
}