@import url("https://fonts.googleapis.com/css?family=Poppins");

section {
  min-height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 50px 0;
  position: relative; }
  section .github-badge {
    position: absolute;
    top: 0;
    left: 0; }
  section h1 {
    text-align: center;
    margin-bottom: 70px; }
  section .hv-container {
    flex-grow: 1;
    overflow: auto;
    justify-content: center; }

.basic-style {
  background-color: #7352C7; }
  .basic-style > h1 {
    color: #7352C7; }

p.simple-card {
  margin: 0;
  background-color: #7352C7;
  color: #7352C7;
  padding: 30px;
  border-radius: 7px;
  min-width: 100px;
  text-align: center;
  box-shadow: 0 3px 6px rgba(204, 131, 103, 0.22); }

.hv-item-parent p {
  font-weight: bold;
  color: #7352C7; }

  .management-hierarchy > h1 {
    color: #7352C7; }
  .management-hierarchy .person {
    text-align: center; cursor: pointer; }
    .management-hierarchy .person > img {
      height: 110px;
      border: 5px solid #7352C7;
      border-radius: 50%;
      overflow: hidden;
      background-color: #7352C7; }
    .management-hierarchy .person > p.name {
      background-color: #7352C7;
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 12px;
      font-weight: normal;
      color: #3BAA9D;
      margin: 0;
      position: relative; }
      .management-hierarchy .person > p.name b {
        color: rgba(59, 170, 157, 0.5); }
      .management-hierarchy .person > p.name:before {
        content: '';
        position: absolute;
        width: 2px;
        height: 8px;
        background-color: #7352C7;
        left: 50%;
        top: 0;
        transform: translateY(-100%); }





      /* treeview  */

      .hv-wrapper {
        display: flex; }
        .hv-wrapper .hv-item {
          display: flex;
          flex-direction: column;
          margin: auto; }
          .hv-wrapper .hv-item .hv-item-parent {
            margin-bottom: 50px;
            position: relative;
            display: flex;
            justify-content: center; }
            .hv-wrapper .hv-item .hv-item-parent:after {
              position: absolute;
              content: '';
              width: 2px;
              height: 25px;
              bottom: 0;
              left: 50%;
              background-color: #7352C7;
              transform: translateY(100%); }
          .hv-wrapper .hv-item .hv-item-children {
            display: flex;
            justify-content: center; }
            .hv-wrapper .hv-item .hv-item-children .hv-item-child {
              padding: 0 15px;
              position: relative; }
              .hv-wrapper .hv-item .hv-item-children .hv-item-child:before, .hv-wrapper .hv-item .hv-item-children .hv-item-child:not(:only-child):after {
                content: '';
                position: absolute;
                background-color: #7352C7;
                left: 0; }
              .hv-wrapper .hv-item .hv-item-children .hv-item-child:before {
                left: 50%;
                top: 0;
                transform: translateY(-100%);
                width: 2px;
                height: 25px; }
              .hv-wrapper .hv-item .hv-item-children .hv-item-child:after {
                top: -25px;
                transform: translateY(-100%);
                height: 2px;
                width: 100%; }
              .hv-wrapper .hv-item .hv-item-children .hv-item-child:first-child:after {
                left: 50%;
                width: 50%; }
              .hv-wrapper .hv-item .hv-item-children .hv-item-child:last-child:after {
                width: calc(50% + 1px); }
      